import { mainAxios } from 'libs/axios'

/**Login Props type */
interface LoginProps {
  mb_id: string
  mb_password: string
  site: string
}

export const loginApi = (payload: LoginProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/login`,
    payload: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
        Referer: '13.250.113.47'
      }
    }
  })
}

export const meApi = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/me`,
    // payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
        Referer: '13.250.113.47'
      }
    }
  })
}

interface idCheckProps {
  id: string
}

export const idCheckApi = (payload: idCheckProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/user/idcheck`,
    params: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
        // Referer: "13.250.113.47",
      }
    }
  })
}

interface nameCheckProps {
  name: string
}

export const nameCheckApi = (payload: nameCheckProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/user/namecheck`,
    params: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
        Referer: '13.250.113.47'
      }
    }
  })
}

interface recCoderCheckProps {
  id: string
}

export const recCodeCheckApi = (payload: recCoderCheckProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/user/reccheck`,
    params: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
        Referer: 'api.toyumwon.com'
      }
    }
  })
}

interface registerProps {
  mb_id: string
  mb_password: string
  mb_repassword: string
  mb_name: string
  mb_hp1: string
  mb_bank_name: string
  mb_bank_no: string
  mb_bank_own: string
  mb_exch_pw: string
  mb_rec_id: string
}

export const registerApi = (payload: registerProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/register`,
    payload: payload,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
        Referer: '13.250.113.47'
      }
    }
  })
}

interface changePasswordProps {
  nowuserpw: string
  mb_password: string
  mb_repassword: string
}

export const changePasswordApi = (payload: changePasswordProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/me/password/change`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const logoutApi = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/logout`,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json',
      }
    }
  })
}
export const logoutApi0 = (payload:any) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/logoutO`,
    requiresToken: true,
    notError: true,
    getError: true,
    body: payload,
    config: {
      headers: {
        contentType: 'application/json',
      }
    }
  })
}
