import styled from 'styled-components'
export const MobileNav = styled.div`
  background: var(--Header-color, #2e2e2e);
  padding: 10px 15px;
  position: relative;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  .logo {
    max-width: 100px;
    width: 100%;
    margin-left: 40px;
    img {
      width: 100%;
    }
  }
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    .logo {
      transform: scale(1.6);
    }
  }

  .user,
  .toggle {
    cursor: pointer;
    @media screen and (max-width: 768px) {
      transform: scale(1.1);
    }
  }
`

// export const IconLoginWrapper = styled.div`
//   svg {
//     font-size: 25px;
//     background: var(
//       --Gold-stroke,
//       linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
//     );
//     background-clip: text;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//   }
// `
export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const MobileInfoUser = styled.div`
  // height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  z-index: 10;
  position: relative;

  background: #2e2e2e;
  .item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .title {
      color: #fff;
      // width: 33%;
    }
    .value {
      background: var(
        --Button-hover,
        linear-gradient(180deg, #f00 0%, #ffd02b 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const Menu = styled.div`
  position: absolute;
  z-index: 20;
  width: 55vw;
  height: 65vh;
  padding-top: 40px;
  top: 0;
  left: calc(-55vw - 45px);
  background: rgba(131, 131, 131, 0.3);
  backdrop-filter: blur(20px);
  transition: all 0.3s;
  &.show {
    left: 0;
  }
  .logout {
    position: absolute;
    bottom: 15px;
    left: calc(50% - 45px);
  }
  .close {
    position: absolute;
    top: 10px;
    right: -40px;
  }
  .menu-item {
    width: 100%;
    height: 46px;
    display: flex;
    justifi-content: center;
    align-items: center;
    padding-left: 14px;
    .img-box {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      span {
        background: var(
          --Gold-stroke,
          linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 15px;
        font-style: 100%;
        font-weight: 500;
      }
      img {
        width: 25px;
        object-fit: contain;
      }
    }
  }
`
