/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
// import btnPartner from 'assets/images/button/btn-partner.png'
import btnAddValue from 'assets/images/button/btn-addvalue.png'
import btnCustomerCenter from 'assets/images/button/btn-customerCenter.png'
import btnWithDraw from 'assets/images/button/btn-withdraw.png'
import btnNoti from 'assets/images/button/btn-noti.png'
import btnMess from 'assets/images/button/btn-adminmess.png'
import btnUser from 'assets/images/button/Button-2.png'
// import btnPartnerBlack from 'assets/images/button/btn-partner-black.png'
// import btnAddValueBlack from 'assets/images/button/btn-addvalue-black.png'
// import btnWithDrawBlack from 'assets/images/button/btn-withdraw-black.png'
// import btnNotiBlack from 'assets/images/button/btn-noti-black.png'
// import btnMessBlack from 'assets/images/button/btn-adminmess-black.png'
// import btnUserBlack from 'assets/images/button/Button-2-black.png'
// import btnLogin from 'assets/images/button/btn-login.png'
// import btnRegister from 'assets/images/button/btn-register.png'
import eventActive from 'assets/images/button/eventNoActive.png'

import btnMenu from 'assets/images/button/ButtonMenu.png'
import userIcon from 'assets/images/user/user.png'
import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/logo.png'
import type { TourProps } from 'antd'

// import logo from 'assets/images/login/World_Logo.png'
// import { Popover, Tooltip } from 'antd'
// import avatar from 'assets/images/avatar.png'
import * as S from './style'
// import changePoint from 'assets/images/Icon.png'
import types from 'store/actions/types'
import CustomButton from 'components/CustomButton'
import { isEmptyObject } from 'utils/ref'
import MobileHeader from 'components/MobileHeader'
import moneyConvert from 'utils/moneyConvert'
import { languageList } from 'common/constants/languageConfig'
import { useTranslation } from 'react-i18next'
import { buildQueryString, changeParamWithLocale } from 'utils'
import useGuide from 'hooks/useGuide'
import useWindowSize from 'hooks/useWindowSize'
import { getObjectLocalData } from 'utils/localStore'
import { logoutApi } from 'apis/auth'

interface Props {
  onClick?: any
  className?: any
}

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [onShowMenu, setOnShowMenu] = useState<boolean>(false)
  const { i18n, t } = useTranslation()

  const [selectLang, setSelectLang] = useState(
    languageList.find((e) => e.lang === i18n.language)
  )
  const [compShow, setCompShow] = useState(false)
  const dispatch = useDispatch()
  const handleOpenModal = (name: string) => {
    dispatch({ type: types.OPEN_MODAL, payload: { name: name } })
  }
  const ref = useRef(null)
  //replace pathname when click back and forward button browser
  useEffect(() => {
    window.addEventListener('popstate', () => {
      history.replace(window.location.pathname)
    })
  }, [])

  const steps = {
    title: t('home.youReceivedMessageFromTheAdmin'),
    description: t('home.checkYourMessage'),
    ...(window.innerWidth > 768 && { target: () => ref.current }),
    nextButtonProps: {
      onClick: () => {
        history.push(UrlInternal.MESSAGE)
        // guide.clear()
      },
      children: <>{t('home.check')}</>
    }
  }
  const guide = useGuide(steps)

  const handleShow = () => {
    setOnShowMenu(!onShowMenu)
  }

  const actionLogOut = async () => {
    try {
      await logoutApi()
    } catch (err) {
      console.log(err)
    }
  }

  const Logout = async () => {
    await actionLogOut()
    sessionStorage.removeItem('User')
    sessionStorage.removeItem('account')
    localStorage.removeItem('Popup')
    dispatch({
      type: types.GET_DATA_USER,
      dataUser: {}
    })

    history.push(UrlInternal.HOME)
    window.location.reload()
  }

  const onClickLogo = () => {
    history.push(UrlInternal.HOME)
  }

  const activeTab = (name: string) => {
    return history.location.pathname === name
  }

  const handleChange = (value: { lang: string }) => {
    i18n.changeLanguage(value.lang)
    changeParamWithLocale(value.lang)
    setSelectLang(value as any)
    localStorage.setItem('lang', value.lang)
    setCompShow(false)
  }

  const handleRedirect = (url: string) => {
    if (isEmptyObject(dataMe.dataUser)) {
      dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })

      return
    }
    history.push(url)
  }
  return (
    <>
      <S.WrapperAuth>
        {isEmptyObject(dataMe.dataUser) ? (
          <>
            <CustomButton onClick={() => handleOpenModal('login')}>
              {t('auth.logIn')}
            </CustomButton>
            <CustomButton onClick={() => handleOpenModal('register')}>
              {t('home.signUp')}
            </CustomButton>
          </>
        ) : (
          <>
            <S.InfoUser>
              <div className='cash-info'>
                <div className='item'>
                  <div className='title'>{t('home.moneyInPossession')}*</div>
                  <div className='value'>
                    {moneyConvert(dataMe.dataUser.mb_money, true)}{' '}
                    {t('home.won')}
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>{t('home.point2')}*</div>
                  <div className='value'>
                    {moneyConvert(dataMe.dataUser.mb_point, true)}{' '}
                    {t('home.point')}
                  </div>
                </div>
              </div>
            </S.InfoUser>
            <S.UserTag onClick={() => handleRedirect(UrlInternal.USER)}>
              <div className='icon'>
                <img src={userIcon} alt='' />
              </div>
              <span>{dataMe.dataUser.mb_name}</span>
              {/* <div className='cash-info'>
                <div className='item'>
                  <div className='title'>보유머니*</div>
                  <div className='value'>{dataMe.dataUser.mb_money} 원</div>
                </div>
                <div className='item'>
                  <div className='title'>포인트*</div>
                  <div className='value'>{dataMe.dataUser.mb_point} 점</div>
                </div>
              </div> */}
            </S.UserTag>
            <CustomButton onClick={() => Logout()}>
              {t('home.logOut')}
            </CustomButton>
          </>
        )}
        <div className='select-lang'>
          <CustomButton onClick={() => setCompShow(!compShow)}>
            {selectLang?.label as string}
          </CustomButton>
          {compShow && (
            <ul>
              {languageList.map((e) => (
                <li
                  onClick={() => handleChange(e)}
                  className={e.lang === selectLang?.lang ? 'active' : ''}
                  key={e.lang}
                >
                  {e.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </S.WrapperAuth>
      <S.Header className='navbar-overlay'>
        {/* <S.Wrapper> */}
        <S.WrapperTop>
          <S.MenuWraper>
            {/* <MenuOutlined
            style={{ fontSize: '32px', color: '#08c' }}
            onClick={handleShow}
          > */}
            <S.BtnMenu onClick={handleShow}>
              <img alt='' src={btnMenu} />
            </S.BtnMenu>
            {/* </MenuOutlined> */}
          </S.MenuWraper>

          <S.MenuGroupList
            onClick={handleShow}
            show={onShowMenu}
          ></S.MenuGroupList>
          <S.TopButton show={onShowMenu}>
            <S.BoxItem
              className={
                activeTab(UrlInternal.DEPOSIT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => handleRedirect(UrlInternal.DEPOSIT)}
            >
              <div className='img-box'>
                {/* <img style={{ marginTop: '-10px' }} alt='' src={btnAddValue} /> */}
                <span>{t('home.recharge')}</span>
                <span className='sub'>DEPOSIT</span>
              </div>

              {/* <img className='active-img' alt='' src={btnAddValueBlack} /> */}
            </S.BoxItem>

            <S.BoxItem
              className={
                activeTab(UrlInternal.PAYMENT)
                  ? 'active content-top large-icon'
                  : 'content-top large-icon'
              }
              onClick={() => handleRedirect(UrlInternal.PAYMENT)}
            >
              <div className='img-box'>
                <span>{t('home.withdraw')}</span>
                <span className='sub'>WITHDRAW</span>
              </div>

              {/* <img className='active-img' alt='' src={btnWithDrawBlack} /> */}
            </S.BoxItem>
            <S.BoxItem
              ref={ref}
              className={
                activeTab(UrlInternal.MESSAGE)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => handleRedirect(UrlInternal.MESSAGE)}
            >
              <div className='img-box'>
                <div className='ctImgMessage'>
                  {dataMe?.dataUser?.count_message ? (
                    <div className='notiMess'>
                      {dataMe?.dataUser?.count_message ?? 0}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <span>{t('home.message')}</span>
                <span className='sub'>MESSAGE</span>
              </div>
              {/* <img className='active-img' alt='' src={btnMessBlack} /> */}
            </S.BoxItem>
            <S.TopLogo onClick={onClickLogo}>
              <img alt='logo' src={logo} />
            </S.TopLogo>
            {/* {dataMe?.dataUser?.mb_option_rec === '1' && (
              <S.BoxItem
                className='content-top'
                onClick={() => window.open(UrlInternal.PARTNER)}
              >
                <div className='img-box'>
                  <img alt='' src={btnPartner} />
                  <span>파트너</span>
                </div>
                <img className='active-img' alt='' src={btnPartnerBlack} />
              </S.BoxItem>
            )} */}
            <S.BoxItem
              className={
                activeTab(UrlInternal.CUSTOMER_CENTER)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => handleRedirect(UrlInternal.CUSTOMER_CENTER)}
            >
              <div className='img-box'>
                <span>{t('home.customerCenter')}</span>
                <span className='sub'>CUSTOMER</span>
              </div>
              {/* <img className='active-img' alt='' src={btnNotiBlack} /> */}
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.NOTIFICATION)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => handleRedirect(UrlInternal.NOTIFICATION)}
            >
              <div className='img-box'>
                <span>{t('home.announcements')}</span>
                <span className='sub'>NOTICE</span>
              </div>
              {/* <img className='active-img' alt='' src={btnNotiBlack} /> */}
            </S.BoxItem>

            <S.BoxItem
              className={
                activeTab(UrlInternal.POINT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => handleRedirect(UrlInternal.POINT)}
            >
              <div className='img-box'>
                <span>{t('home.changePoint')}</span>
                <span className='sub'>POINT CONVERT</span>
              </div>

              {/* <img className='active-img' alt='' src={btnUserBlack} /> */}
            </S.BoxItem>
            {+dataMe?.dataUser?.set_rolling === 1 && (
              <S.BoxItem
                className='content-top'
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_ADMIN_DOMAIN +
                      `${buildQueryString(getObjectLocalData<any>('account'))}`
                  )
                }
              >
                <div className='img-box'>
                  {/* <img style={{ marginTop: '-10px' }} alt='' src={btnAddValue} /> */}
                  <span>{t('home.partner')}</span>
                  <span className='sub'>Partner</span>
                </div>

                {/* <img className='active-img' alt='' src={btnAddValueBlack} /> */}
              </S.BoxItem>
            )}
          </S.TopButton>
        </S.WrapperTop>
      </S.Header>
      <MobileHeader />
      <guide.Tour
        // cl={false as any}
        zIndex={99}
        open={guide.guideState.open}
        steps={guide.guideState.steps}
      />
    </>
  )
}

export default HeaderPage
