import types from '../actions/types'

const defaultState = {
  isOpen: false,
  msg: '',
  type: '',
  typePopup: '',
  actionText: null,
  cancelText: null,
  linkToPage: '',
  isCustomNotice: false,
  title: '',
  fromURL: null,
  guide: {
    open: true,
    steps: [],
  }
}

export default (state = defaultState, action: any) => {
  const type = action?.type
  switch (type) {
    case types.POPUP_ACTION_OPEN:
      return { ...state, type, isOpen: true, ...action?.payload }
    case types.POPUP_ACTION_CLOSE:
      return { ...state, type, isOpen: false, ...action?.payload }
    case types.OPEN_GUIDE: 
      return {...state, guide: {open: true, steps: [...state.guide.steps, action.payload.steps]}}
    case types.CLEAR_GUIDE: 
      return {...state, guide: {open: false, steps: []}}
    default:
      return state
  }
}
