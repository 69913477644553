import { Tour } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import types from 'store/actions/types'
import { pause, play } from 'utils/sound'

const useGuide = (steps?: any) => {
  const dataMe = useSelector((state: any) => state.user)
  const popup = useSelector((state: any) => state.popup)
  const dispatch = useDispatch()
  const path = useLocation().pathname
  useEffect(() => {
    if (dataMe?.dataUser?.count_message && path !== UrlInternal.MESSAGE) {
      play()
      dispatch({
        type: types.OPEN_GUIDE,
        payload: { open: true, steps: steps }
      })
    }
  }, [dataMe?.dataUser?.count_message])
  const clear = () => {
    pause()
    dispatch({ type: types.CLEAR_GUIDE })
  }
  return {
    Tour,
    guideState: popup.guide,
    clear
  }
}

export default useGuide
