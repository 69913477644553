// eslint-disable-next-line @typescript-eslint/no-explicit-any

import React, { lazy, useEffect, useRef, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/reset.css'

/** Common */
import { UrlInternal } from 'common/constants/endpoints'
import GlobalStyles from 'common/style/GlobalStyle'
import Normalize from 'common/style/Normalize'
import defaultTheme from 'common/style/themes/default'
import { meApi } from 'apis'
import types from 'store/actions/types'
import Modal from 'components/Modal'

/** Router Global */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PrivateRoute, PublicRoute } from './router' //PrivateRoute,
import { PopupCheck, WaittingPage } from 'components'

/** Utils */
import ScrollToTop from 'utils/ScrollToTop'
import { useDispatch, useSelector } from 'react-redux'
import { getLangParams, isEmptyObject } from 'utils'
import { loginApi, logoutApi, logoutApi0 } from 'apis/auth'
import { saveLocalStorage } from 'utils/localStorage'

/** Page */
const Login = lazy(() => import('./pages/Login'))

// const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import('./pages/Register'))
const Home = lazy(() => import('./pages/Home'))
// const Register = lazy(() => import("./pages/Register"));
const Deposit = lazy(() => import('./pages/Deposit'))
const Payment = lazy(() => import('./pages/Payment'))
const Points = lazy(() => import('./pages/Points'))
const CustomerCenterPage = lazy(() => import('./pages/CustomerCenterPage'))
const CustomerCenterPageRegister = lazy(
  () => import('./pages/CustomerCenterPageRegister')
)
const CustomerCenterPageDetail = lazy(
  () => import('./pages/CustomerCenterPageDetail')
)
const Message = lazy(() => import('./pages/Message'))
const NotiAndEvent = lazy(() => import('./pages/NotiAndEventPage'))
const NotificationDetail = lazy(() => import('./pages/NotificationDetail'))
// const Event = lazy(() => import("./pages/EventPage"));
const EventDetail = lazy(() => import('./pages/EventDetail'))
const CasinoPage = lazy(() => import('./pages/CasinoPage'))
// const CasinoPageDetail = lazy(() => import("./pages/CasinoPageDetail"));
const Maintain = lazy(() => import('./pages/SiteMaintain'))
const ListSlot = lazy(() => import('pages/ListOpSlot'))
const ListCasino = lazy(() => import('pages/ListOpCasino'))
// const SlotGamePage = lazy(() => import("./pages/SlotGamePage"));
// const SlotGamePageDetail = lazy(() => import("./pages/SlotGamePageDetail"));
// const MiniGamePage = lazy(() => import("./pages/MiniGamePage"));
// const MiniGameDetailPage = lazy(() => import("./pages/MiniGameDetailPage"));
// const SportsBettingPage = lazy(() => import("./pages/SportsBettingPage"));
const InfoPage = lazy(() => import('./pages/InfoPage'))
// const PageNotFound = lazy(() => import("./pages/PageNotFound"));

const Blank = lazy(() => import('./pages/Blank'))

// console.log("getDataById", a);

export const getMe = async (dispatch: any) => {
  try {
    const res: any = await meApi()
    if (res.result === false) {
      alert(res.message)
    } else {
      dispatch({
        type: types.GET_DATA_USER,
        dataUser: res
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({ type: 'STOP_WAITTING' })
  }
}
export const getNoti = async (dispatch: any) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await meApi()
    if (res.result === false) {
      alert(res.message)
    } else {
      dispatch({
        type: types.GET_DATA_USER,
        dataUser: res
      })
    }
  } catch (error) {
    console.log(error)
  }
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const userAuthen = sessionStorage.getItem('User')
  const userData = useSelector((state: any) => state.user)
  const url = window.location.href
  useEffect(() => {
    getMe(dispatch)

    const timeRunning = setInterval(() => {
      getMe(dispatch)
    }, 10000)
    return () => {
      clearInterval(timeRunning)
    }
    // const authenticateUser = async () => {
    //   if (userAuthen && !url.includes('/blank/') && !accountSession) {
    //     await getMeNew().then((res) => {
    //       dispatch({
    //         type: types.GET_DATA_USER,
    //         dataUser: res
    //       })
    //       dispatch({
    //         type: types.GET_TOKEN,
    //         token: JSON.parse(userAuthen).value
    //       })
    //       setToken(JSON.parse(userAuthen).value)
    //     })
    //     const timeRunning = setInterval(() => {
    //       getMe(dispatch);
    //     }, 10000);
    //     return () => {
    //       clearInterval(timeRunning)
    //     }
    //   } else {
    //     if(url.includes('/blank/') && accountSession) return

    //     try {
    //       if (accountSession) {
    //         dispatch({ type: 'WAITTING' })
    //         const user = JSON.parse(accountSession).value
    //         const body = {
    //           mb_id: user.mb_id,
    //           mb_password: user.mb_password,
    //           site: 'ak7788'
    //         }
    //         const res: any = await loginApi(body)
    //         if (res?.result) {
    //           saveLocalStorage({
    //             name: 'User',
    //             data: res?.access_token,
    //             type: 'session'
    //           })
    //           saveLocalStorage({ name: 'account', data: body, type: 'session' })
    //           setToken(res?.access_token)
    //           dispatch({
    //             type: types.GET_TOKEN,
    //             token: res?.access_token
    //           })
    //           await getMeNew().then((res)=>{
    //             dispatch({
    //               type: types.GET_DATA_USER,
    //               dataUser: res
    //             })
    //             dispatch({ type: 'STOP_WAITING' });

    //           })

    //           const timeRunning = setInterval(() => {
    //             getMe(dispatch)
    //           }, 10000)
    //           return () => {
    //             clearInterval(timeRunning)
    //           }
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error)
    //     } finally {
    //       dispatch({ type: 'STOP_WAITTING' })
    //     }
    //   }
    // }
    // authenticateUser();
  }, [])
  useEffect(() => {
    if (!url.includes('/blank/')) {
      const evtSource = new EventSource(
        `${process.env.REACT_APP_CLIENT_URL}/me/keep-token?sse_token=${userAuthen ? JSON.parse(userAuthen).value : ''}`
      )
      evtSource.onmessage = (event) => {
        console.log(event)
      }
    }
  }, [userAuthen])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Modal />
        <Switch>
          <PublicRoute exact path={UrlInternal.HOME} component={Home} />
          <PublicRoute exact path={UrlInternal.REGISTER} component={Register} />
          <PublicRoute exact path={UrlInternal.MAINTAIN} component={Maintain} />
          <PrivateRoute exact path={UrlInternal.LOGIN} component={Login} />

          <PrivateRoute exact path={UrlInternal.DEPOSIT} component={Deposit} />
          <PrivateRoute
            exact
            path={UrlInternal.CASINO}
            component={CasinoPage}
          />

          <PrivateRoute exact path={UrlInternal.POINT} component={Points} />
          <PrivateRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER}
            component={CustomerCenterPage}
          />
          <PrivateRoute exact path={UrlInternal.PAYMENT} component={Payment} />
          <PrivateRoute exact path={UrlInternal.DEPOSIT} component={Deposit} />
          <PrivateRoute exact path={UrlInternal.USER} component={InfoPage} />
          {/* <Route exact path={UrlInternal.POINT} component={Points} /> */}
          <PrivateRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER}
            component={CustomerCenterPage}
          />
          <PrivateRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER_REGISTER}
            component={CustomerCenterPageRegister}
          />
          <PrivateRoute
            exact
            path={UrlInternal.CUSTOMER_CENTER_DETAIL}
            component={CustomerCenterPageDetail}
          />
          <PrivateRoute exact path={UrlInternal.MESSAGE} component={Message} />
          <PrivateRoute
            exact
            path={UrlInternal.NOTIFICATION}
            component={NotiAndEvent}
          />
          <PrivateRoute
            exact
            path={UrlInternal.EVENT}
            component={NotiAndEvent}
          />
          <PrivateRoute
            exact
            path={UrlInternal.NOTIFICATION_DETAIL}
            component={NotificationDetail}
          />
          <PrivateRoute
            exact
            path={UrlInternal.EVENT_DETAIL}
            component={EventDetail}
          />
          <PrivateRoute exact path={UrlInternal.BLANK} component={Blank} />
          <PrivateRoute
            exact
            path={UrlInternal.NEW_SLOT}
            component={ListSlot}
          />
          <PrivateRoute
            exact
            path={UrlInternal.NEW_CASINO}
            component={ListCasino}
          />
        </Switch>
        {/* <FooterPage /> */}
      </Router>
      <PopupCheck />
      <WaittingPage />
    </ThemeProvider>
  )
}

export default App
