import React, { useEffect, useMemo, useState } from 'react' //lazy
import { popupAPI } from 'apis'

import * as S from './style'
import { useHistory } from 'react-router-dom'
import { setObjectLocalData, getObjectLocalData } from 'utils/localStore'
interface Props {
  data?: any
}
type Items = Array<{ id?: number; expired?: Date }>
//props: Props
const PopupConfirm = ({ data = [] }: Props) => {
  const [items, setItems] = useState<any>([])
  const history = useHistory()
  const getPopup = async () => {
    const res: any = await popupAPI()
    if (res) {
      setItems(res?.data)
    }
    if (res?.error?.message === 'Site close') {
      history.push('site-maintain')
      localStorage.setItem('isMaintain', 'true')
    }
  }

  useEffect(() => {
    getPopup()
  }, [])

  const close = (id: number) => {
    setItems((p: any) => {
      p = p.filter((e: any) => e.board_id !== id)
      return p
    })
  }

  const closeBox = (id: number) => {
    const tomorrow = new Date()
    tomorrow.setHours(23, 59, 59)
    let p = getObjectLocalData<Items>('Popup') || []
    const exitedItem = p?.find((e) => e?.id === id)
    if (exitedItem) {
      p.forEach((e) => {
        if (e.id === exitedItem.id) {
          e.expired = tomorrow
        }
      })
    } else {
      p = [...p, { id, expired: tomorrow }]
    }
    setObjectLocalData('Popup', p)
    close(id)
  }

  const calcDisplayItems = useMemo(() => {
    const localItem = getObjectLocalData<Items>('Popup') || []
    const current = new Date()
    if (!localItem.length) return items
    const mustShowItems = items.map((item: any) => {
      const matched = localItem.find((e) => e.id === item.board_id)
      if (
        new Date(`${matched?.expired}`) < current &&
        matched?.id === item.board_id
      ) {
        return item
      }
      if (matched?.id !== item.board_id) {
        return item
      }
    })
    return mustShowItems.filter(Boolean)
  }, [items])

  return (
    <S.Wrapper>
      {!!calcDisplayItems?.length &&
        calcDisplayItems.map((item: any) => (
          <S.ItemContainer key={item?.board_id}>
            <S.Item
              // className={`${arrIdsClose.includes(item?.board_id) ? 'false' : ''} main`}
              style={{
                width: '100%',
                maxWidth: Number(item?.board_field5),
                height: '100%',
                border:
                  item?.board_field1 === '1'
                    ? `4px solid ${item?.board_color}`
                    : 'unset'
              }}
            >
              <div
                className='content-html'
                dangerouslySetInnerHTML={{
                  __html: item?.board_content?.replace(/\n/g, '<br />') ?? ''
                }}
              />
              <div className='content'>
                <div className='box' onClick={() => closeBox(item?.board_id)}>
                  {' '}
                </div>
                <div className='text'> &ensp;&emsp; 오늘 그만보기</div>
              </div>
              <div className='button'>
                <span onClick={() => close(item?.board_id)}>CLOSE</span>
              </div>
            </S.Item>
          </S.ItemContainer>
        ))}
    </S.Wrapper>
  )
}

export default PopupConfirm
