/**
 * @author [Peter]
 * @email [hoangvanlam9988@mail.com]
 * @create date 2022-02-19 15:25:35
 * @modify date 2022-02-19 15:25:35
 * @desc [description]
 */

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { UrlInternal } from 'common/constants/endpoints'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { getLocalStorage } from 'utils/localStorage'
import { isEmptyObject } from 'utils/ref'
import types from 'store/actions/types'
import { changeParamWithLocale } from 'utils'
import { getStringLocalData } from 'utils/localStore'
export function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const isMaintain = localStorage.getItem('isMaintain') || null
  const user = sessionStorage.getItem('User')
  console.log(history.location.pathname)
  useLayoutEffect(() => {
    // getLocalStorage({ typeGetData: 'User', type: 'session' }).then((v: any) => {
    if (!user && isMaintain === null) {
      history.replace({
        pathname:'',
        state: { fromURL: history.location.pathname }
      })
      dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })
      return
    }
    dispatch({ type: types.CLOSE_MODAL, payload: { name: 'login' } })
    // })
  }, [history.location.pathname])
  const { pathname } = useLocation()
  useEffect(() => {
    changeParamWithLocale(getStringLocalData('lang') || '')
  }, [pathname])

  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />
}

export function PrivateRoute({
  component: TargetPage,
  isAuthenticated = true,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  useEffect(() => {
    changeParamWithLocale(getStringLocalData('lang') || '')
  }, [pathname])
  // useLayoutEffect(() => {
  //   getLocalStorage({ typeGetData: 'User', type: 'session' }).then((v: any) => {
  //     if (!userData && !v) {
  //       history.push({
  //         pathname: UrlInternal.LOGIN,
  //         state: { fromURL: history.location.pathname }
  //       })
  //     }
  //   })
  // }, [])
  const isMaintain = localStorage.getItem('isMaintain') || null
  useLayoutEffect(() => {
    const user = sessionStorage.getItem('User')
    // getLocalStorage({ typeGetData: 'User', type: 'session' }).then((v: any) => {
    if (!user && isMaintain === null) {
      history.push({
        pathname: UrlInternal.HOME,
        state: { fromURL: history.location.pathname }
      })
      dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })
      return
    }
    dispatch({ type: types.CLOSE_MODAL, payload: { name: 'login' } })
    // })
  }, [history.location.pathname])

  return (
    <Route
      {...rest}
      render={(props: any) =>
        !isEmptyObject(userData) ? (
          <TargetPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: UrlInternal.LOGIN,
              state: { fromURL: props?.location?.pathname }
            }}
          />
        )
      }
    />
  )
}
