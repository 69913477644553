import Loading from 'containers/Loading'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as StoreProvider } from 'react-redux'
import initStore from 'store'
import App from './App'
import { SearchBarHeader } from './components'
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from 'react-i18next'
import i18n from 'translation/i18n'
import './index.css'
// import './utils/logout'
const store = initStore()

// function getLoading() {
//   setInterval(() => {}, 2000)
//   return <Loading />
// }

ReactDOM.createRoot(document.getElementById('root')!).render(
  <I18nextProvider i18n={i18n}>
    <StoreProvider store={store}>
      <Suspense fallback={<Loading />}>
        {/* <Loading /> */}
        <App />
        <SearchBarHeader />
      </Suspense>
    </StoreProvider>
  </I18nextProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
