import sound from '../assets/sound.mp3'
const media = new Audio(sound)
media.loop = true

const start = () =>{
  media.play().catch(e => {
    console.log(e);
    
  })
}

export const play = () => {
  start()
  window.addEventListener('click', start)
}

export const pause = () =>{
  media.pause()
  window.removeEventListener('click', start)
}