import btnUser from 'assets/images/button/Button-2.png'
import btnAddValue from 'assets/images/button/btn-addvalue.png'
import btnMess from 'assets/images/button/btn-adminmess.png'
import btnNoti from 'assets/images/button/btn-noti.png'
import partner from 'assets/images/button/partner.png'
import btnPartner from 'assets/images/button/btn-partner.png'
import btnWithDraw from 'assets/images/button/btn-withdraw.png'
import closeIcon from 'assets/images/icon/close-menu.png'
import btnCustomerCenter from 'assets/images/button/btn-customerCenter.png'
import { UrlInternal } from 'common/constants/endpoints'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Menu } from './style'
import CustomButton from 'components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'
import { useTranslation } from 'react-i18next'
import { getObjectLocalData, getStringLocalData } from 'utils/localStore'
import { buildQueryString, isEmptyObject } from 'utils'
import { logoutApi } from 'apis/auth'

type Props = {
  onClose: () => void
  show: boolean
  hideLogout: boolean
}

const FloatMenu: React.FC<Props> = ({ show, onClose, hideLogout }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const dataMe = useSelector((state: any) => state.user)
  const { t } = useTranslation()

  const actionLogOut = async () => {
    try {
      await logoutApi()
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogout = async () => {
    await actionLogOut()
    sessionStorage.removeItem('User')
    sessionStorage.removeItem('account')
    localStorage.removeItem('Popup')
    dispatch({
      type: types.GET_DATA_USER,
      dataUser: {}
    })
    history.push(UrlInternal.HOME)
    onClose()
    window.location.reload()
  }

  const handleRedirect = (url: string) => {
    if (isEmptyObject(dataMe.dataUser)) {
      dispatch({ type: types.OPEN_MODAL, payload: { name: 'login' } })

      return
    }
    history.push(url)
  }

  return (
    <>
      <Menu className={show ? 'show' : ''}>
        {+dataMe?.dataUser?.set_rolling === 1 && (
          <div
            className='menu-item'
            onClick={() =>
              window.open(
                process.env.REACT_APP_ADMIN_DOMAIN +
                  `${buildQueryString(getObjectLocalData<any>('account'))}`
              )
            }
          >
            <div className='img-box'>
              <img alt='' src={partner} />
              <span>{t('home.partner')}</span>
            </div>
          </div>
        )}

        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.DEPOSIT)}
        >
          <div className='img-box'>
            <img alt='' src={btnAddValue} />
            <span>입금</span>
          </div>
        </div>
        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.PAYMENT)}
        >
          <div className='img-box'>
            <img alt='' src={btnWithDraw} />
            <span>출금</span>
          </div>
        </div>
        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.MESSAGE)}
        >
          <div className='img-box'>
            <img alt='' src={btnMess} />
            <span>쪽지</span>
          </div>
        </div>
        {/* {dataMe?.dataUser?.mb_option_rec === '1' && (
          <div className="menu-item"
            onClick={() => window.open(UrlInternal.PARTNER)}
          >
            <div className='img-box'>
              <img alt='' src={btnPartner} />
              <span>파트너</span>
            </div>
          </div>
        )} */}
        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.NOTIFICATION)}
        >
          <div className='img-box'>
            <img alt='' src={btnNoti} />
            <span>공지사항</span>
          </div>
        </div>
        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.POINT)}
        >
          <div className='img-box'>
            <img alt='' src={btnUser} />
            <span>포인트전환</span>
          </div>
        </div>
        <div
          className='menu-item'
          onClick={() => handleRedirect(UrlInternal.CUSTOMER_CENTER)}
        >
          <div className='img-box'>
            <img alt='' src={btnCustomerCenter} />
            <span>고객센터</span>
          </div>
        </div>
        {!hideLogout && (
          <div className='logout'>
            <CustomButton onClick={handleLogout}>로그아웃</CustomButton>
          </div>
        )}

        <div className='close' onClick={onClose}>
          <img src={closeIcon} alt='' />
        </div>
      </Menu>
    </>
  )
}

export default FloatMenu
