import { mainAxios } from 'libs/axios'

interface CustomerCenterProps {
  limit?: string | number
  page?: string | number,
  sort?: string
}

export const getCustomerCenter = (payload: CustomerCenterProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cscenter`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface customerCenterDetailProps {
  id?: string | number
}

export const getCustomerCenterDetail = (payload: customerCenterDetailProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cscenter/${payload?.id}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getSendAccount = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cscenter/send-account`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface CreateCustomerCenterProps {
  board: string | number
  board_html: string | number
  qString: string | number
  board_field9: string | number
  board_subject: string | number
  board_content: string | number
}

export const createCustomerCenter = (payload: CreateCustomerCenterProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/cscenter/input`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DeleteMessageProps {
  ids?: any
}

export const deleteCustomerCenter = (payload: DeleteMessageProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/cscenter/delete-board01`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const readCsCenterAll = (payload: any) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/cscenter/read-all`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
