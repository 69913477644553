import styled from 'styled-components'

// interface ItemProps {
//   bg?: any;
// }

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 99;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px;

  @media (max-width: 798px) {
    top: 0px;
  }
`

export const ItemContainer = styled.div`
  @media (max-width: 798px) {
    position: absolute;
    z-index: 9999999;
  }
`

export const Item = styled.div`
  background: #000;
  font-size: 12px;
  color: #fff;
  display: block;
  padding: 10px;

  &.false {
    display: none;
  }

  .content-html {
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .content {
    margin: -6px 1px 2px;
    position: relative;
    .box {
      position: absolute;
      top: 0px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cbcbcc;
      font-size: 600;
    }
    .text {
      line-height: 20px;
    }
  }
  .button {
    width: 100%;
    padding-top: 8px;
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }

  @media (max-width: 798px) {
    min-width: 100vw;
  }
`
